<template>
	<div id="meetList">
		<div class="top" v-loading="loading">
			<!-- <div class="title">项目：{{projectNmae}}</div> -->
			<!-- {{projectInfo}} -->
			<div class="kong">
				<div class="item i1" @click="creatorMeet()" v-if="projectInfo.projectType!='single'">
					<img src="../../../assets/imgs/kong1.png" alt="">
					<span>创建会议</span>
				</div>
				<!-- <div class="item i2" @click="sectionChange({ class: 'UPLOAD' })">
					<img src="../../../assets/imgs/kong2.png" alt="">
					<span>待会后上传</span>
					<i class="redDot" v-if="upNum&&upNum>0">{{upNum}}</i>
				</div> -->
				<div class="item i3" @click="goDoctorList()">
					<img src="../../../assets/imgs/kong3.png" alt="">
					<span>项目医生</span>
				</div>
			</div>
		</div>
		<!-- 项目列表 -->
		<div class="hzCont" v-loading="loading">
			<h1 v-if="todayMeetList[0]">今日会议</h1>
			<el-carousel height="350px" v-if="todayMeetList[0]" indicator-position="inside" :autoplay="false">
				<el-carousel-item v-for="item in todayMeetList" :key="item.id">
					<div class="bgImg">
						<img v-if="item.bgImage" :src="item.bgImage">
						<img v-else src="../../../assets/imgs/back2.png">
						<span></span>
					</div>
					<div class="main">
						<div class="tit">
							主题：
							{{item.meetingTitle}}
						</div>
						<div class="pro">
							<img src="../../../assets/imgs/icon1.png">
							所属项目：
							{{item.projectName}}
						</div>
						<div class="time">
							<img src="../../../assets/imgs/icon2.png">
							开始时间：
							{{$moment(item.meetingStartTime).format('L') }}
							{{$moment(item.meetingStartTime).format('LT') }}
						</div>
						<div class="users">
							<img src="../../../assets/imgs/icon3.png">
							参会医生：
							{{item.doctors}}
						</div>
						<el-button class="goLive" @click="enterTheMeeting(item)">进入会议</el-button>
					</div>
				</el-carousel-item>
			</el-carousel>
			<div class="tabs">
				<template v-for="(item,i) in list">
					<p :class="item.class == curNow ? 'active':''" :key="i" @click="tabsChange(item)">
						{{item.name}}
						<span v-if="item.class == 'WAIT'">{{recentTotal}}</span>
						<i></i>
					</p>
					<el-divider direction="vertical" :key="i" v-if="i<list.length-1"></el-divider>
				</template>
			</div>
			<div class="searth">
				<el-input v-model="searthForm.name" placeholder="请输入会议名称(关键词查找)" clearable> </el-input>
				<el-select v-model="searthForm.location" filterable clearable placeholder="请选择地区">
					<el-option v-for="item in locationList" :key="item.id" :label="item.location"
						:value="item.location">
					</el-option>
				</el-select>
				<el-button @click="getMeetingList()" slot="append" type="primary" icon="el-icon-search">搜索</el-button>
				<el-button @click="searthForm={},getMeetingList()" slot="append" type="info"
					icon="el-icon-search">重置</el-button>

			</div>
			<div class="list">
				<h1>
					<b>会议列表</b>
					<span>我的配额：{{projectInfo.myMeetingNum }} </span>
					<span>已用配额： {{ projectInfo.useNum}}</span>
				</h1>
				<div class="item" v-for="item in meetingList" :key="item.id" @click="toMeetingDetails(item)">
					<div class="tags">
						<div class="type" v-if="item.meetingType == 'single'&&item.parentId">
							<img src="../../../assets/imgs/video.png">追加会
						</div>
						<div class="type" v-else-if="item.meetingType == 'single'">
							<img src="../../../assets/imgs/video.png">单场会
						</div>
						<div class="type" v-else-if="item.meetingType == 'series'">
							<img src="../../../assets/imgs/video2.png">系列会
						</div>
						<div class="type" v-else></div>
						<div class="idNum">
							<template v-if="item.parentId">
								源自:{{item.parentId}}
							</template>
							&nbsp;
							编号:{{item.id}}
						</div>
					</div>
					<div class="main">
						<div class="left">
							<div class="tit">主题：{{item.meetingTitle}}</div>
							<div class="time" v-if="item.meetingStartTime">
								会议时间：
								{{ $moment(item.meetingStartTime).format('YYYY-MM-DD') }}
								{{ $moment(item.meetingStartTime).format('LT') }}
							</div>
							<div class="type">
								<el-tag size="mini" v-if="item.meetingStatus == 'WAIT'" type="info">待开始</el-tag>
								<el-tag size="mini" v-if="item.meetingStatus == 'WAIT_CLAIM'"
									type="warning">待认领</el-tag>
								<el-tag size="mini" v-if="item.meetingStatus == 'LIVING'" type="info">进行中</el-tag>
								<el-tag size="mini" v-if="item.meetingStatus == 'FINISH'" type="info">已结束</el-tag>
								<el-tag size="mini" v-if="item.meetingStatus == 'UPLOAD'" type="info">待上传证明</el-tag>
								<el-tag size="mini" v-if="item.meetingStatus == 'AI_REVIEW'" type="success">预审中</el-tag>
								<el-tag size="mini" v-if="item.meetingStatus == 'AI_REVIEW_ERROR'"
									type="danger">预审驳回</el-tag>
								<el-tag size="mini" v-if="item.meetingStatus == 'AI_REVIEW_ERROR_ACCEPT'"
									type="success">官员审核</el-tag>
								<el-tag size="mini" v-if="item.meetingStatus == 'AI_REVIEW_SUCCESS'"
									type="success">官员审核</el-tag>
								<el-tag size="mini" v-if="item.meetingStatus == 'OFFICIAL_ERROR'"
									type="danger">官员驳回</el-tag>
								<el-tag size="mini" v-if="item.meetingStatus == 'OFFICIAL_SUCCESS'"
									type="success">财务审核</el-tag>
								<el-tag size="mini" v-if="item.meetingStatus == 'ERROR'" type="danger">财务驳回</el-tag>
								<el-tag size="mini" v-if="item.meetingStatus == 'SUCCESS'" type="success">审核通过</el-tag>
								<el-tag size="mini" v-if="item.location" type="primary">{{item.location}}</el-tag>
								<el-tag size="mini" v-if="item.reimbursementNum" type="primary">
									编号:{{item.reimbursementNum}}</el-tag>

							</div>
						</div>
						<div class="opts">
							<el-button @click.stop="claimMeeting(item)" type="warning"
								v-if="item.meetingStatus == 'WAIT_CLAIM'">认领会议</el-button>
							<el-button @click.stop="toMeetingDetails(item)" type="primary" v-else>会议详情</el-button>
							<el-button type="primary" @click.stop="enterTheMeeting(item)"
								v-if="item.meetingStatus == 'WAIT'||item.meetingStatus=='LIVING'">进入会议</el-button>
							<el-button @click.stop="uploadContent(item)" v-if="item.meetingStatus == 'UPLOAD'"
								type="warning">劳务结算</el-button>
							<el-button @click.stop="uploadContent(item)"
								v-if="item.meetingStatus == 'AI_REVIEW_ERROR'||item.meetingStatus == 'OFFICIAL_ERROR'||item.meetingStatus == 'ERROR'"
								type="warning">重新上传</el-button>
							<!-- <el-button type="primary" @click.stop="supplierSettlement(item)"
								v-if="item.meetingStatus!='WAIT_CLAIM'&&item.meetingStatus!='WAIT'&&item.meetingStatus!='LIVING'">供应商结算</el-button> -->
						</div>
					</div>
				</div>
				<div class="null" v-if="!meetingList[0]">
					<img :src="require('@/assets/imgs/EmptyState.png')" alt="">
					<p>暂时没有会议内容</p>
				</div>
			</div>
			<el-pagination background layout="prev, pager, next" :total="total" :page-size="size"
				:current-page.sync="page" class="pagination" @current-change="getMyProject"
				:hide-on-single-page="false"></el-pagination>
		</div>

		<!-- 供应商结算 -->
		<el-dialog custom-class="settleAccounts" v-if="settleAccountsShow" :visible.sync="settleAccountsShow"
			title="请选择收款方式及费用类型" width="600px" center>
			<el-form ref="form" :model="accountForm" label-position="left">
				<el-row>
					<el-col :span="12">
						<el-form-item label="收款方式:">
							<el-select size="small" v-model="accountForm.collectWay" placeholder="请选择活动区域">
								<el-option v-for="(item,i) in collectWayEnums" :key="i" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="费用类型:">
							<el-select size="small" v-model="accountForm.feeType" placeholder="请选择活动区域">
								<el-option label="会议类" value="0"></el-option>
								<el-option label="非会议类" value="1" disabled></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div class="tips">
				<h3>请仔细阅读以下说明</h3>
				<p>如有 <b>一次性收款</b> ，直接选择“一次性收款并结项”</p>
				<p>如有 <b>两次收款</b> ，第一次选择“申请首次收款”，第二次选择“申请尾款”</p>
				<p>如有 <b>分三次及三次以上收款</b> ，第一次选择“申请首次收款”，中间付款选择“申请阶段收款”，最后一笔选择“申请尾款”</p>
				<p><b>据实结算</b> 的收款方式仅支持费用类型是会议类，框架服务模式，按场次收款的项目，可多次申请</p>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="toSupplierSettlement">确 定</el-button>
			</span>
		</el-dialog>

		<!-- 公告 -->
		<el-dialog custom-class="Notice" v-if="NoticeShow" :visible.sync="NoticeShow"
			:title="`${projectInfo.projectName} 公告`" width="800px" center>
			<div class="NoticeMain" v-html="Notice.message"></div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="checkNotice">我已阅读并知晓</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		todayMeeting,
		getMeetingUrl,
		uploadMeetingNum,
		meetList,
		projectList,
		getProjectById,
		getCollectWay,
		getOrderByMeetId,
		getLocationListass,
		acceptProjectNotice,
		getProjectNotice
	} from '@/request/api.js';
	export default {
		name: 'meetList',
		components: {},
		data() {
			return {
				loading: false,
				projectInfo: '',
				projectNmae: '',
				curNow: "WAIT",
				upNum: 0,
				page: 1,
				size: 5,
				recentTotal: 0,
				total: 0,
				locationList: [],
				meetingList: [],
				todayMeetList: [],
				list: [{
					name: '近期会议',
					class: 'WAIT',
				}, {
					name: '待会后上传',
					class: 'UPLOAD',
				}, {
					name: '审核中会议',
					class: 'REVIEW',
				}, {
					name: '已结束会议',
					class: 'FINISH',
				}],
				message: '',

				searthForm: {
					name: "",
					project: "",
				},

				projectList: [],
				timer: null,

				settleAccountsShow: false,
				accountForm: {
					feeType: '0',
					collectWay: ""
				},
				collectWayEnums: [],
				orderData: '',
				ckMeetingId: '',

				Notice: '', // 公告
				NoticeShow: false
			}
		},
		created() {
			this.projectInfo = JSON.parse(localStorage.getItem('projectDetails'));
			this.getTodayMeeting();
			this.getUploadMeetingNum();
			this.getMeetingFirst()
			this.getMeetingList();
			this.getProjectList();
			this.getLocationListFun();
			this.getNotice()
			this.getProjectByIdFun();
		},
		mounted() {},
		methods: {
			getProjectByIdFun() {
				getProjectById({
					id: this.$route.query.pId
				}).then(res => {
					if (res.code == 200) {
						this.projectInfo = res.data
					}
				})
			},

			checkNotice() {
				acceptProjectNotice({
					noticeId: this.Notice.id,
					projectId: this.projectInfo.id
				}).then(res => {
					if (res.code == 200) {
						this.NoticeShow = false;
					}
				})
			},

			getNotice() {
				getProjectNotice({
					projectId: this.projectInfo.id
				}).then(res => {
					if (res.code == 200) {
						this.Notice = res.data
						if (!this.Notice.isCheck) {
							this.NoticeShow = true
						}
						// console.log(this.Notice)
					}
				})
			},

			// 获取地区列表
			getLocationListFun() {
				getLocationListass().then(res => {
					if (res.code == 200) {
						this.locationList = res.data
					}
				})
			},

			// 跳转供应商结算页面
			toSupplierSettlement() {
				this.$router.push({
					name: 'supplierSettlement',
					query: {
						mId: this.ckMeetingId,
						collectWay: this.accountForm.collectWay,
						feeType: this.accountForm.feeType
					}
				})
			},

			// 供应商结算
			supplierSettlement(item) {
				this.ckMeetingId = item.id;
				this.loading = true;
				this.getCollectWayFun(item);

				getOrderByMeetId({
					meetingId: item.id,
				}).then(res => {
					this.orderData = res.data;
					if (this.orderData) {
						this.loading = false;
						this.toSupplierSettlement()
						console.log('有记录')
					} else {
						this.loading = false;
						this.settleAccountsShow = true;
						console.log('无记录')
					}
				})
			},

			getCollectWayFun(item) {
				getCollectWay({
					// projectId: this.projectId,
					meetingId: item.id
				}).then(res => {
					if (res.code == 200) {
						// console.log(res.data)
						this.collectWayEnums = res.data;
						if (this.collectWayEnums.length == 1) {
							this.accountForm.collectWay = this.collectWayEnums[0].value;
						}
					};
				});
			},

			// 获取项目列表
			getProjectList(query) {
				let data = {
					current: 1,
					size: 30,
					projectName: query
				}

				projectList(data).then(res => {
					if (res.code == 200) {
						this.projectList = res.data.records;
					}
				})
			},

			// 认领会议/修改会议信息
			claimMeeting(item) {
				this.$router.push({
					name: 'createmeet',
					query: {
						pId: this.$route.query.pId,
						mId: item.id
					}
				})
			},

			// 查看会议详情
			toMeetingDetails(item) {
				this.$router.push({
					name: 'meetdetails',
					query: {
						mId: item.id,
						status: item.meetingStatus,
						now: '3'
					}
				})
			},
			uploadContent(item) {
				console.log(item)
				this.$router.push({
					name: 'uploadFile',
					query: {
						mId: item.id,
						// status: item.meetingStatus,
						// now: '4'
					}
				})
			},

			goDoctorList() {
				this.$router.push({
					name: 'doctor',
				})
			},

			// 创建会议
			creatorMeet() {
				if (this.projectInfo.myMeetingNum > this.projectInfo.useNum) {

					this.$router.push({
						name: 'createmeet',
						query: {
							pId: this.$route.query.pId
						}
					})
				} else {
					this.$alert('您在本项目中的会议配额已达上限，<br/>如需新建会议请联系项目官员。', '配额超限', {
						dangerouslyUseHTMLString: true,
						confirmButtonText: '确定',
						type: "warning",
						center: true
					});
				}
			},

			// 获取会议列表
			getMeetingList() {
				let data = {
					projectId: this.$route.query.pId,
					size: this.size,
					current: this.page,
					meetingType: this.curNow,
				};
				if (this.searthForm.name) data.meetingTitle = this.searthForm.name;
				if (this.searthForm.location) data.location = this.searthForm.location;
				else data.meetingTitle = '';

				meetList(data).then(res => {
					if (res.code == 200) {
						let resData = res.data.records
						resData.map(item => {
							if (item.id == item.parentId) delete item.parentId;
							return item;
						})

						this.meetingList = resData;
						this.total = res.data.total;
						if (this.meetingList[0]) this.projectNmae = this.meetingList[0].projectName;
					}
				})
			},

			getMeetingFirst() {
				let data = {
					projectId: this.$route.query.pId,
					meetingType: "WAIT"
				};
				meetList(data).then(res => {
					if (res.code == 200) {
						this.recentTotal = res.data.total;
					}
				})
			},

			// 查询会后待上传数量
			getUploadMeetingNum() {
				let data = {
					projectId: this.$route.query.pId
				};
				uploadMeetingNum(data).then(res => {
					if (res.code == 200) {
						this.upNum = res.data
					}
				})

			},

			// 进入会议
			enterTheMeeting(item) {
				let data = {
					meetingId: item.id
				}
				getMeetingUrl(data).then(res => {
					if (res.code == 200) {
						// console.log(res)
						window.open(res.data)
					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						});
						this.$notify({
							type: 'error',
							title: '错误',
							message: res.msg,
							position: 'bottom-right'
						});
					}
				})
			},

			// 获取今日会议列表
			getTodayMeeting() {
				let data = {
					// projectId: this.projectId
				};
				todayMeeting(data).then(res => {
					if (res.code == 200) {
						let data = res.data;
						data.map(item => {
							item.doctors = item.doctors.split(',').join("，");
							return item;
						})

						this.todayMeetList = data;
						// this.$forceUpdate();
					}
				})
			},
			getOederData(id) {

			},

			// 分页切换
			getMyProject(e) {
				// console.log(e)
				this.page = e;
				this.getMeetingList();
			},

			// 切换会诊选项
			tabsChange(item) {
				this.curNow = item.class;
				this.page = 1;
				this.getMeetingList();
			},

			sectionChange(item) {
				this.curNow = item.class;
				this.page = 1;
				this.getMeetingList();
				// this.curNow = 'FINISH';
			}
		}
	}
</script>

<style lang="scss" scoped>
	#meetList {
		display: flex;
		height: auto;
		flex-direction: column;

		.top {
			margin: 20px 20px 0;
			padding: 20px 20px 0;
			box-shadow: 0 2px 15px rgba(203, 203, 203, 0.31);
			border-radius: 20px;

			.title {
				font-size: 20px;
				margin-bottom: 20px;
			}

			.kong {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
				margin-bottom: 20px;

				.item {
					width: 270px;
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					position: relative;
					padding: 10px;
					border-radius: 50px;
					margin-right: 20px;

					.redDot {
						top: 0;
						right: 0;
						color: #fff;
						width: 26px;
						height: 26px;
						display: flex;
						font-size: 14px;
						border-radius: 50%;
						font-style: normal;
						position: absolute;
						border: 3px solid #fff;
						background-color: #D43030;
						justify-content: center;
						align-items: center;
						font-weight: bold;
					}

					* {
						cursor: pointer;
					}

					img {
						width: 60px;
						height: 60px;
					}

					span {
						width: 100%;
						font-size: 18px;
						text-align: center;
						margin-right: 30px;
					}
				}

				.i1 {
					background-color: #D5E5F7;
				}

				.i2 {
					background-color: #FFF4DB;
				}

				.i3 {
					background-color: #F3FFDB;
				}
			}

		}

		.hzCont {
			flex: 1;
			padding: 20px;
			display: flex;
			flex-direction: column;
			border-top-left-radius: 20px;
			border-top-right-radius: 20px;


			.tabs {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
				margin-bottom: 20px;

				.el-divider {
					width: 2px;
					height: 30px;
					margin: 0;
				}

				p {
					width: 200px;
					padding: 10px 5px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 18px;
					cursor: pointer;
					line-height: 2.5;
					position: relative;

					i {
						width: 90%;
						left: 50%;
						transform: translateX(-50%);
						bottom: 0;
						height: 0;
						background-color: #0065FF;
						position: absolute;
						transition: all .3s;
					}

					span {
						margin-left: 10px;
						height: 26px;
						width: 40px;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 12px;
						background-color: #007AFF;
						border-radius: 5px;
						color: #fff;
					}
				}

				.active i {
					height: 6px;
				}
			}

			::v-deep .searth {
				display: flex;
				margin-bottom: 20px;

				.el-input,
				.el-select {
					flex: 1;
					margin-right: 10px;
				}
			}

			.list {
				flex: 1;

				h1 {
					color: #000;
					font-size: 20px;
					margin-bottom: 20px;
					display: flex;
					align-items: center;

					b {
						margin-right: 50px;
					}

					span {
						font-size: 14px;
						color: #666;
						margin-right: 30px;
					}
				}

				.itemToday {
					display: flex;
					margin-bottom: 20px;
					flex-direction: row;
					padding: 20px;
					box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
					transition: all .3s;
					border-radius: 10px;
					align-items: center;

					&:hover {
						background-color: #E8EEFC;
					}

					.main {
						flex: 1;
						display: flex;
						flex-direction: column;

						.tag {
							display: flex;
							margin-bottom: 10px;

							span {
								height: 20px;
								background-color: #007AFF;
								font-size: 12px;
								border-radius: 5px;
								color: #fff;
								display: flex;
								align-items: center;
								padding: 0 10px;
							}
						}

						.tit {
							font-size: 16px;
							color: #000;
							margin-bottom: 10px;
							line-height: 1;
						}

						.time {
							font-size: 13px;
							color: #007AFF;
							line-height: 1;
						}
					}

					.goLive {
						background: #0065FF;
						color: #fff;
						padding: 0 25px;
						height: 28px;
					}
				}

				.el-carousel {
					margin-bottom: 10px;
				}

				.el-carousel__item {
					display: flex;
					flex-direction: row;

					.bgImg {
						flex: 1;
						height: 100%;
						overflow: hidden;
						object-fit: cover;

						img {
							width: 100%;
							height: 100%;
							display: block;
							object-fit: cover;
							border-radius: 20px;
						}
					}

					.main {
						flex: 1;
						padding: 20px 0 20px 40px;
						display: flex;
						flex-direction: column;
						align-items: space-between;
						justify-content: space-between;

						>div {
							font-size: 16px;
							display: flex;
							align-items: center;
							color: #112950;

							img {
								height: 16px;
								margin-right: 5px;
							}
						}

						.tit {
							font-size: 22px;
							color: #000;
							word-wrap: break-word;
							white-space: normal;
							word-break: break-all;
							// overflow: hidden;
							// white-space: nowrap;
							// text-overflow: ellipsis;
						}

						.goLive {
							color: #fff;
							height: 50px;
							background-color: #D43030;
						}
					}
				}

				.item {
					display: flex;
					margin-bottom: 20px;
					flex-direction: column;
					padding: 20px;
					box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
					transition: all .3s;
					border-radius: 10px;

					&:hover {
						background-color: #E8EEFC;
					}

					.tags {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						margin-bottom: 10px;

						.type {
							display: flex;
							align-items: center;
							color: #000;
							font-size: 12px;

							img {
								margin-right: 5px;
								width: 20px;
								height: 15px;
							}
						}

						.idNum {
							font-size: 12px;
							color: #999;
						}
					}



					.main {
						display: flex;
						flex-direction: row;
						justify-content: space-between;

						.left {
							.tit {
								font-size: 16px;
								color: #333;
								margin-bottom: 10px;
								line-height: 1;
								font-weight: bold;
							}

							.time {
								font-size: 13px;
								color: #666;
								line-height: 1;
								margin-bottom: 10px;
							}

							.type {
								display: flex;

								.el-tag {
									margin-right: 4px;
								}

								.el-button--text {
									padding: 0;
									font-size: 13px;
								}

								.WAIT {
									color: #007AFF;
								}

								.FINISH {
									color: #999;
								}
							}
						}

						.opts {
							display: flex;
							align-items: flex-end;

							.el-button {
								padding: 0 25px;
								height: 28px;
							}

							.el-button--primary {
								background-color: #0065ff;
							}

							.el-button--warning {
								background-color: #E6A23C;
							}

							.el-button--danger {
								background-color: #F56C6C;
							}

							.agreement {
								background-color: #D43030;
								color: #fff;
								padding: 0 25px;
								height: 28px;
							}
						}
					}
				}

				.null {
					width: 100%;
					padding: 100px;
					display: flex;
					align-items: center;
					flex-direction: column;
					justify-content: center;

					img {
						width: 200px;
					}

					p {
						font-size: 18px;
						color: #A6A6A6;
					}
				}
			}

			.el-pagination {
				margin: auto;
			}
		}

	}

	::v-deep .settleAccounts {

		.el-dialog__body {
			padding-bottom: 10px;
		}

		.tips {
			background-color: #f6cdbb;
			padding: 20px;
			border-radius: 10px;

			h3 {
				margin-bottom: 10px;
			}

			p {
				margin-bottom: 5px;

				b {
					color: red;
				}
			}
		}
	}

	::v-deep .Notice {
		margin: 0 !important;
		max-height: 95vh;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		// .el-dialog__header {
		// 	padding: 0;
		// }

		// .el-dialog__body {
		// 	padding: 0;
		// }

		.el-dialog__headerbtn {
			top: 20px;
			right: 20px;
		}
	}
</style>